<template>

    <div>

        <!--Header-->
        <div slot="header" style="margin-bottom: 15px;">

            <span class="d-flex w-100 justify-content-between flex-wrap">

                <!--Left side-->
                <span>
                    <!--Title-->
                    <h5 style="margin-bottom: 0"><b>Floor Plans</b></h5>
                </span>

                <!--Right side-->
                <span style="display: inline-block; white-space: nowrap">
                    Constellation:
                    <b-form-select v-model="currentConstellation" :options="constellations" style="width: fit-content"
                                   @input="getFloorPlans()">
                    </b-form-select>
                </span>

            </span>

        </div>

        <!--Picture Window-->
        <div id="picture-window"
             style="display: flex; justify-content: center; flex-direction: column; align-items: center;">

            <!--Image Parent-->
            <div id="panzoom-element">
                <img id="img" :src="image" v-if="uploadedBool === true">
            </div>

        </div>

        <!--Select Floor Plans Table-->
        <div style="margin-top: 15px;">

            <v-client-table @row-click="loadFloorPlan" ref="dTable" style="width: 100%" :columns="columns"
                            :data="floorPlans" :options="tOptions" :theme="theme" class="dataTable">
                <div slot="num_devices" slot-scope="props" style="float: left">
                    {{ props.row.devices.length }}
                </div>
            </v-client-table>

        </div>

    </div>

</template>

<script>
import Vue from 'vue';
import constellation from "@/services/constellation";
import blackhole from '../../services/blackhole.js';
import floorPlanStore from "@/store/floorPlanStore";
import Panzoom from '@panzoom/panzoom';
import {ClientTable} from 'vue-tables-2';
import Clappr from "clappr";
import PlaybackRatePlugin from "clappr-playback-rate-plugin";
import LevelSelector from "level-selector";

Vue.use(ClientTable);

export default {
    name: "Floor_View",

    created() {
        // retrieve constellation data for form select search
        floorPlanStore.dispatch('getConstellations');
    },

    destroyed() {
        floorPlanStore.dispatch('resetVariables');
    },

    data() {
        return {
            clapprPlayer: null,
            columns: ['name', 'num_devices'],
            theme: 'bootstrap4',
            tOptions: {
                sortable: ['name', 'num_devices'],
                filterable: ['name', 'num_devices'],
                orderBy: {column: 'name', ascending: true},
                headings: {
                    name: 'Name',
                    num_devices: 'Number of Devices'
                },
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 10,
                skin: 'table table-striped table-hover',
            },
        }
    },

    computed: {
        currentConstellation: {
            set(constellation) {
                floorPlanStore.commit('SET_CURRENT_CONSTELLATION', constellation);
            },
            get() {
                return floorPlanStore.getters.getCurrentConstellation();
            }
        },
        constellations: {
            get() {
                return floorPlanStore.getters.getConstellations();
            }
        },
        devices: {
            get() {
                return floorPlanStore.getters.getDevices();
            }
        },
        floorPlans: {
            get() {
                return floorPlanStore.getters.getFloorPlans();
            }
        },
        image: {
            get() {
                return floorPlanStore.getters.getImage();
            }
        },
        imageNumber: {
            get() {
                return floorPlanStore.getters.getImageNumber();
            }
        },
        uploadedBool: {
            get() {
                return floorPlanStore.getters.getUploadedBool();
            }
        },
    },

    methods: {
        configureDeviceIcons() {
            // for all devices, get more information on them with call then
            // create appropriate image to overlay on floor plan image
            for (let i = 0; i < this.devices.length; i++) {
                //TODO Change to use store
                constellation.readDevice(this.currentConstellation.url, this.devices[i].deviceId).then(response => {
                    // create div for icon to go in
                    let tempDiv = document.createElement("div");
                    let tempId = "image" + this.imageNumber;
                    tempDiv.setAttribute("style", "width: 30px; height: 30px; position: absolute;");
                    tempDiv.style.top = this.devices[i].coords.yCoord + 'px';
                    tempDiv.style.left = this.devices[i].coords.xCoord + 'px';
                    tempDiv.id = tempId;
                    floorPlanStore.dispatch('incrementImageNumber');

                    // create image icon to go on floor plan
                    let image = document.createElement("img");
                    image.style.height = floorPlanStore.state.iconSize+'px';
                    image.style.width = floorPlanStore.state.iconSize+'px';
                    image.style.transform = "rotate(" + this.devices[i].angle + "deg)";
                    floorPlanStore.dispatch('setDeviceType', response.data.devicetype);
                    image.src = floorPlanStore.getters.getDeviceImage();
                    image.style.cursor = "pointer";
                    image.addEventListener("click", function () {
                        this.onDeviceClick(tempId);
                    }.bind(this));
                    if (response.data.devicetype === "camera") {
                        image.addEventListener("mouseenter", function () {
                            this.hoverCameraOn(response.data._id, this.devices[i]);
                        }.bind(this));
                        image.addEventListener("mouseleave", function () {
                            this.hoverCameraOff();
                        }.bind(this));
                    }

                    // append image to overlay and append that to grid
                    tempDiv.append(image);
                    document.getElementById("panzoom-element").append(tempDiv);
                });
            }
        },
        getFloorPlans() {
            // get all floor plans from respective constellation
            constellation.readFloorPlans(this.currentConstellation.url).then(response => {
                floorPlanStore.dispatch('setFloorPlans', response.data);
            }).then(() => {
                this.$mToast({
                    title: 'Success',
                    text: "Floor plans successfully acquired",
                    style: 'success'
                });
            }).catch(() => {
                this.$mToast({
                    title: 'Error',
                    text: 'Unsuccessfully acquired floor plans',
                    style: 'error'
                });
            });
        },
        hoverCameraOff() {
            // remove all traces of video and its container div
            this.clapprPlayer.destroy();
            document.getElementById("video-window").remove();
        },
        hoverCameraOn(cameraId, device) {
            // create Clappr player for the marker's video feed
            let player = new Clappr.Player({
                source: "https://" + this.currentConstellation.url + "/elasticband/stream/adaptive/" +
                    cameraId + "/live.m3u8?tokenId=" + constellation.get_constellation_token(),
                plugins: {
                    'core': [Clappr.MediaControl, PlaybackRatePlugin, LevelSelector]
                },
                hideMediaControl: true,
                playback: {
                    playInline: true, // allows inline playback when running on iOS UIWebview
                    recycleVideo: Clappr.Browser.isMobile,
                },
                maxBufferLength: 2,
                hlsjsConfig: {
                    debug: false,
                    liveBackBufferLength: 4,
                    maxBufferLength: 2
                },
                mute: true,
                autoPlay: true,
                width: '100%',
                height: '100%'
            });
            this.clapprPlayer = player;

            // attach the Clappr player to div and put it where it needs to be
            let videoWindow = document.createElement("div");
            videoWindow.id = "video-window";
            videoWindow.setAttribute("style", "height: 243px; width: 386px; position: absolute;");
            videoWindow.style.top = (device.coords.yCoord - 273) + 'px';
            videoWindow.style.left = (device.coords.xCoord - 193) + 'px';
            document.getElementById("panzoom-element").append(videoWindow);
            Vue.nextTick().then(() => {
                player.attachTo(document.getElementById('video-window'));
            });
        },
        loadFloorPlan(floorPlan) {
            // handling for getting photo from blackhole
            floorPlanStore.dispatch('setImage', blackhole.readImage(floorPlan.row.image));

            // reset everything in case floor plan has been previously loaded
            // remove image elements and rotate icons from document
            if (this.uploadedBool === true) {
                for (let i = 0; i < this.imageNumber; i++) {
                    let tempName = "image" + i;
                    let currImg = document.getElementById(tempName);
                    try {
                        currImg.remove();
                    } catch (e) {

                    }
                }
            }

            // set the height of the floor plan picture window to a fixed size smaller than the 'main' height
            let eee = document.getElementsByClassName("main")[0];
            let pictureWindowHeight = eee.clientHeight - 625;
            floorPlanStore.dispatch('setPictureWindowHeight', pictureWindowHeight);
            pictureWindowHeight = floorPlanStore.getters.getPictureWindowHeight();
            document.getElementById("picture-window").style.height = (pictureWindowHeight) + 'px';
            document.getElementById("picture-window").style.backgroundColor = "black";
            floorPlanStore.dispatch('setUploadedBool', true);

            // get icon size before device icons are configured
            (async () => {
                let img = new Image();
                img.src = this.image;
                await img.decode();
                // img is ready to use
                floorPlanStore.dispatch('setIconSize', {
                    height: img.height,
                    width: img.width
                });
                floorPlanStore.dispatch('setDevices', floorPlan.row.devices);
                this.configureDeviceIcons();

                // panzoom stuff
                const elem = document.getElementById('panzoom-element');
                const panzoom = Panzoom(elem, {
                    maxScale: 5
                });
                panzoom.pan(10, 10);
                elem.addEventListener('wheel', panzoom.zoomWithWheel);
            })();

        },
        onDeviceClick(id) {},
    }
}
</script>